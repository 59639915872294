import {
    createSlice,
} from '@reduxjs/toolkit';

export const initialState = {
    orderDetails: {},
    orderNotes: [],
    postParams: {
        orderNum: '',
        region: ''
    },
    selectedSKUInfo: {
        orderNum: '',
        sku: '',
        pmkey: '',
        warehouseId: '',
        division: '',
        startDate: '',
        endDate: '',
    },
    movementData: [],
    relatedOrders: [],
    ticketHistory: [],
    isLoading: false,
    region: '',
};

export const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        setRegion: (state, action) => {
            return {
                ...state,
                region: action.payload
            };
        },
        setParams: (state, action) => {
            state.postParams = {
                orderNum: action.payload.orderNum,
                region: action.payload.region
            }
        },
        setOrderDetails: (state, action) => {
            state.isLoading = false;
            state.orderDetails = action.payload;
        },
        setOrderNotes: (state, action) => {
            state.isLoading = false;
            state.orderNotes = action.payload;
        },
        setSelectedSku: (state, action) => {
            state.selectedSKUInfo = action.payload;
        },
        setMovementData: (state, action) => {
            state.isLoading = false;
            state.movementData = action.payload;
        },
        setRelatedOrders: (state, action) => {
            state.isLoading = false;
            state.relatedOrders = action.payload;
        },
        setTicketHistory: (state, action) => {
            state.isLoading = false;
            state.ticketHistory = action.payload;
        },
        resetOrderDetails: (state) => {
            return {
                ...state,
                isLoading: true,
                orderDetails: {},
                orderNotes: [],
            };
        },
        resetSelectedSku: (state) => {
            return {
                ...state,
                selectedSKUInfo: {
                    orderNum: '',
                    sku: '',
                    pmkey: '',
                    warehouseId: '',
                    division: '',
                    startDate: '',
                    endDate: '',
                },
            };
        },
        resetMovementData: (state) => {
             return {
                ...state,
                isLoading: true,
                movementData: [],
            };
        },
        resetHistoryData: (state) => {
             return {
                ...state,
                isLoading: true,
                ticketHistory: [],
            };
        }
    },
    
});

export const {
    setRegion,
    setParams,
    setOrderDetails,
    setOrderNotes,
    setSelectedSku,
    setMovementData,
    setRelatedOrders,
    setTicketHistory,
    resetOrderDetails,
    resetSelectedSku,
    resetMovementData,
    resetHistoryData,
} = orderSlice.actions;

export default orderSlice.reducer;