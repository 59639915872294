import { useSelector } from 'react-redux';
import {
    Box,
    Grid,
    Stack,
    Paper,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Divider,
    TableCell,
    Skeleton,
} from '@mui/material';

import {
    CircleOutlined,
    CircleTwoTone,
} from '@mui/icons-material';

import { H3, P1 } from '../HelperComponents/Typography';
import styled from '@emotion/styled';

import { IsArrayEmpty } from '../../utils/helpers';

const HeaderItem = styled(Paper)`
    background-color: #1565c0;
    color: #fff;
    margin: 1em;
    border-radius: 0;
    h3{
        padding: .5em;
    }
`;

const PastCircleIcon = styled(CircleOutlined)`
    color: #D5E7F7;
`;

const CurrentCircleIcon = styled(CircleTwoTone)`
    color: #1565c0;
`;

const DividerCurrent = styled(Divider)`
    border-width: 1px;
    border-color: #1565c0;
    height: 2em;
    float: left;
`;

const DividerPast = styled(Divider)`
    border-width: 1px;
    border-color: #D5E7F7;
    height: 2em;
    float: left;
`;

const HeaderSkeleton = styled(Skeleton)`
    height: 39px;
    margin: 16px
`;

const BodySkeleton = styled(Skeleton)`
    height: 600px;
    margin: 16px;
`;


function NotesRowOutput(note) {
    return (
        <Box key={note.note.key}>
            {
                note.note.index === 0 ? 
                    null :
                    note.note.isLast ? 
                        <DividerCurrent
                            orientation='vertical'
                            sx={{
                                marginLeft: ['6.5%', '6%', '4.75%', '4.75%', '5%', '3.75%', '3.5%', '3.25%', '2.4%']
                            }}
                        /> :
                        <DividerPast
                            orientation='vertical'
                            sx={{
                                marginLeft: ['6.5%', '6%', '4.75%', '4.75%', '5%', '3.75%', '3.5%', '3.25%', '2.4%']
                            }}
                        />
            }
            
            <ListItem>
                <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: 'transparent' }}>
                        {note.note.isLast ? 
                            <CurrentCircleIcon fontSize='large' /> : 
                            <PastCircleIcon fontSize="large" />
                        }
                    </Avatar>    
                </ListItemAvatar>
                <Stack>
                    <Stack direction='row'>
                        <Box>
                            <H3>{note.note.title}</H3>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: '1em'
                            }}
                        >
                            <P1>{note.note.date}</P1>
                        </Box>
                    </Stack>
                    <Box>
                        <P1>{note.note.note}</P1>
                    </Box>
                </Stack>
                
            </ListItem>
        </Box>
    );
}

export default function OrderNotesTab() {
    const notes = useSelector((state) => state.order.orderNotes);

    if (IsArrayEmpty(notes)) {
        return (
            <Box sx={{
                flexGrow: 1,
                overflowX: 'hidden',
            }}>
                <Grid container>
                    <Grid xs={12}>
                        <HeaderSkeleton variant='rectangular'  />
                    </Grid>
                    <Grid xs={12}>
                        <BodySkeleton variant='rectangular' />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box sx={{
            flexGrow: 1,
            overflowX: 'hidden',
        }}>
             <Grid container>
                <Grid xs={12}>
                    <HeaderItem>
                        <H3>Order Notes</H3>
                    </HeaderItem>
                </Grid>
                <Grid xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        maxHeight:'65vh',
                        margin: '1em',
                        backgroundColor: '#fafafa'
                    }}>
                        <List sx={{
                            width: '100%',
                            maxWidth: '80%',
                            justifyContent: 'center',
                            margin: '1em',
                            backgroundColor: '#fff',
                            height: '100%',
                        }}>
                            {
                                notes.map((n, index) => <NotesRowOutput note={n} idx={index} />) 
                            }
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}