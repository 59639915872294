import { useMemo, useState } from 'react';
import moment from 'moment';

import {
    Box,
    Stack,
    Button,
    Checkbox,
    Paper,
    InputBase,
    Collapse,
    Skeleton,
    CircularProgress,
} from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';

import { acquireAccessToken, callMsGraph } from '../../../services/msGraph';

import { useDispatch, useSelector } from 'react-redux';
import {
    resetFilterList,
    searchOrders,
    getProductList,
    resetProductList,
    setPreviousSearch,
} from '../../../store/slices/productSlice';

import {
    setRegion,
    resetOrderDetails,
} from '../../../store/slices/orderSlice';

import dayjs from 'dayjs';

import { H1, P2 } from '../../../components/HelperComponents/Typography'; 
import {
    DateField,
    NumberField,
} from '../../../components/HelperComponents/InputFields';

import CollapsibleTable from '../../../components/CollapsibleTable/CollapsibleTableContainer';

import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const HeaderBox =  styled(Box)`
    border-bottom: 3px solid #00529F;
    padding-bottom: 10px;
    margin-top:3em;
`;

const SearchBox = styled(Box)`
    align-items: center;
    background-color: #F5F5F5;
    margin-top: 18px;
    margin-bottom: 10px;
    padding: 8px 46px;
    .h2box{
        border-bottom: 3px solid #00529F;
        h2{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    .input-field-container{
        p{
            margin-bottom: 13.82px;
        }
        input { 
            padding: 8px 12px 9px;
        }
    }
    
    .apply-btn{
        width: 100%;
    }
`;

function TranslateDate(dateIn){
    return moment(dateIn.$d).format("MM-DD-YYYY");
}

function GetYesterdayDate() {
    const date = new Date();
    if (date.getDay() === 1) {
        date.setDate(date.getDate() - 2);
    }
    else {
        date.setDate(date.getDate() - 1);
    }
    return `${date.getMonth() < 12 ? date.getMonth() + 1 : 1}-${date.getDate()}-${date.getFullYear()}`;
}

export default function MainPage({ productList, addSKUList, isLoading}) {
    const dispatch = useDispatch();

    const [date, setDate] = useState(dayjs(GetYesterdayDate()));
    const [route, setRoute] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const savedSearch = useSelector((state) => state.product.previousSearchValues);

    const isApplyButtonDisabled = useMemo(() => {
        if (route === '') {
            return true;
        }
        return false;
    }, [route]);

    const searchProducts = async () => {
        const token =  await acquireAccessToken();
        const graphCall = await callMsGraph(token);
        const { extensionAttribute3 } = graphCall.onPremisesExtensionAttributes;
        
        const searchVars = {
            date: TranslateDate(date),
            route: route,
            divison: extensionAttribute3,
            isExpected: true
        };
        setSearchInput('');
        dispatch(resetOrderDetails());
        dispatch(resetFilterList());
        dispatch(resetProductList());
        dispatch(setRegion(extensionAttribute3));
        dispatch(setPreviousSearch(searchVars));
        dispatch(getProductList(searchVars));
    };

    const handleIsExpected = async () => {
        setSearchInput('');
        const updatedSaved = {
            ...savedSearch,
            isExpected: !savedSearch.isExpected
        };
        dispatch(setPreviousSearch(updatedSaved));
        dispatch(getProductList(updatedSaved));
    }

    const succesfulLoad = useSelector((state) => state.product.hasLoadedSuccessful);
    const isProductsLoading = useSelector((state) => state.product.isProductsLoading);
    const isFirstLoad = useSelector((state) => state.product.isFirstLoad);

    if (isLoading) {
        return (
            <Box p={2}>
                <HeaderBox>
                    <H1>{'Route Search'}</H1>
                </HeaderBox>
                    <SearchBox>
                        <Box
                            spacing={1}
                            mt='10px'
                            mb='10px'
                        >
                            <Skeleton variant='rectangular' height='70px'/>
                        </Box>
                    </SearchBox>
                <Box>
                    <Box borderBottom='1px solid #A3A3A3' mb={2}>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            ml={6}
                            mr={6}
                            mb='10px'
                        >
                           <Box
                                display='flex'
                                flexWrap='wrap'
                                alignItems='center'
                            >
                                <Checkbox
                                    defaultChecked
                                    checked={savedSearch.isExpected}
                                    disabled={true}
                                    onChange={handleIsExpected}
                                />
                                <P2>{"Expected Only"}</P2>
                            </Box>
                            <Paper
                                sx={{ display: 'flex', alignItems: 'center', width: 215, border: '1px solid #D9D9D9', boxShadow: 'none' }}
                                
                                >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    inputProps={{ 'aria-label': '' }}
                                    variant="outlined" 
                                    value={searchInput}
                                    onChange={(e) => {
                                        setSearchInput(e.target.value);
                                        dispatch(resetFilterList());
                                        dispatch(searchOrders(e.target.value));
                                    }}
                                    disabled={true}
                                />
                                <SearchOutlined sx={{ p: '10px', color: !productList.length > 0 ? '#888888': 'inherit' }} />
                            </Paper>
                        </Stack>
                    </Box>
                    <Box
                        mb={4}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            minHeight: '57px'
                        }}
                    >
                        <Skeleton variant='rectangular' height='57px' />
                    </Box>
                </Box>
            </Box>
        )
    }

    return(
        <Box p={2}>
            <HeaderBox>
                <H1>{'Route Search'}</H1>
            </HeaderBox>
            <SearchBox>
                <Stack
                    direction='row'
                    spacing={1}
                    mt='10px'
                    mb='10px'
                    justifyContent='space-between'
                >
                    <NumberField
                        value={route}
                        setValue={setRoute}
                        labelText={'Route'}
                    />
                    <DateField
                        value={date}
                        setValue={setDate}
                        labelText={'Delivery Date:'} 
                    />
                    <Box width='33%'>
                        <Button
                            className='apply-btn'
                            variant="contained"
                            size='large'
                            disabled={(isProductsLoading || isApplyButtonDisabled)}
                            onClick={searchProducts}
                        >{isProductsLoading ? 
                                <CircularProgress color="inherit" size='1.75em' />:
                            'Apply'
                        }</Button>
                    </Box>
                </Stack>
            </SearchBox>
            <Box>
                <Box borderBottom='1px solid #A3A3A3' mb={2}>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        ml={6}
                        mr={6}
                        mb='10px'
                    >
                        <Box
                            display='flex'
                            flexWrap='wrap'
                            alignItems='center'
                        >
                            <Checkbox
                                defaultChecked
                                checked={savedSearch.isExpected}
                                disabled={!productList.length > 0}
                                onChange={handleIsExpected}
                            />
                            <P2>{"Expected Only"}</P2>
                        </Box>
                        <Paper
                            sx={{ display: 'flex', alignItems: 'center', width: 215, border: '1px solid #D9D9D9', boxShadow: 'none' }}
                            >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                inputProps={{ 'aria-label': '' }}
                                variant="outlined" 
                                value={searchInput}
                                onChange={(e) => {
                                    setSearchInput(e.target.value);
                                    dispatch(resetFilterList());
                                    dispatch(searchOrders(e.target.value));
                                }}
                                disabled={isFirstLoad}
                            />
                            <SearchOutlined sx={{ p: '10px', color: !productList.length > 0 ? '#888888': 'inherit' }} />
                        </Paper>
                    </Stack>
                </Box>
                <Box
                    mb={4}
                    sx={{
                        minHeight: succesfulLoad ? 80 : 57
                    }}
                >
                    <Collapse collapsedSize={isFirstLoad ? 57 : productList.length === 0 ? 80 : 'auto'} in={succesfulLoad}>
                        <CollapsibleTable
                            productList={productList}
                            isFirstLoad={isFirstLoad}
                            isProductsLoading={isProductsLoading}
                        />
                    </Collapse>
                </Box>
            </Box>
        </Box>
    )
}

MainPage.prototype = {
     orderList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            orderNumber: PropTypes.string.isRequired,
            dispatchCode: PropTypes.string.isRequired,
            skus: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    qty: PropTypes.number.isRequired,
                    sku: PropTypes.string.isRequired,
                    description: PropTypes.string.isRequired,
                    delivered: PropTypes.number.isRequired,
                    returned: PropTypes.bool.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    ),
    addSKUList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            sku: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    ),
    isLoading: PropTypes.bool.isRequired,
}

//#region Save For Later Items

/** 
             SAVE FOR LATER - ADD UNEXPECTED COMPONENT
            
             <AddBox>
                <Box className="h2box">
                    <H2>{"Add Unexpected"}</H2>
                </Box>
                 <Stack
                    direction='row'
                    spacing={1}
                    mt='10px'
                    mb='24px'
                    justifyContent='space-between'
                >
                    <ImputFieldNoLabel placeholder={'SKU#'} setInputFn={setSkuNum} />
                    <FormControl sx={{minWidth: '33%', marginTop: '2px !important'}} size='small'>
                        <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Add Reason Select' }}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        >
                        <MenuItem value="">
                            <em>Reason</em>
                        </MenuItem>
                            <MenuItem value={'Option1'}>Option 1</MenuItem>
                            <MenuItem value={'Option2'}>Option 2</MenuItem>
                            <MenuItem value={'Option3'}>Option 3</MenuItem>
                        </Select>
                    </FormControl>
                    <Box width='33%'>
                        <Button
                            variant="contained"
                            size='large'
                            onClick={() => dispatch(addUnexpected({ skuNum: skuNum, description: reason }))}
                        >Add</Button>
                    </Box>
                </Stack>
            </AddBox>
            <Box>
                <StandardTable skuList={addSKUList}/>
            </Box>

            *** UNUSED STYLES
           
            const AddBox = styled(Box)`
                align-items: center;
                background-color: #F5F5F5;
                margin-top: 40px;
                margin-bottom: 10px;
                padding-left: 46px;
                padding-right: 46px;
                .h2box{
                    border-bottom: 3px solid #00529F;
                    h2{
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
                .input-field-container{
                    margin-top: 2px;
                    input { 
                        padding: 8px 12px 9px;
                    }
                }
                button{
                    width: 100%;
                }
            `;

            *** UNUSED STATE VARS
            
            const [skuNum, setSkuNum] = useState('');
            const [reason, setReason] = useState('');

            *** MUI IMPORTS
           
            FormControl,
            FormControlLabel,
            Select,
            MenuItem,

            *** REDUX PRODUCT SLICE IMPORTS
           
            addUnexpected,

            *** COMPONENT IMPORTS
            (InputFields)

            ImputFieldNoLabel,

            (Other Components)

            import StandardTable from '../../../components/StandardTable/StandardTableContainer';
 */

//#endregion