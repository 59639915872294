import axios from 'axios';

const getAllProducts = async (date, route, division, isExpected) => {
    const apiCall = axios.create({
        baseURL: process.env.REACT_APP_API_URL
            + `api/GetDeliveryDataByDateAndRoute?division=${division === null ? 'FL' : division}&date=${date}&route=${route}&isExpectedOnly=${isExpected}`
    });
    const response = await apiCall.get();
    try {
        return JSON.parse(response.data.Data);
    } catch (error) {
        return error;
    }
}

const ProductServices = {
    getAllProducts
};

export default ProductServices;