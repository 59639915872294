import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack,
    Button,
    IconButton,
} from '@mui/material';
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
} from '@mui/icons-material';

import { useSelector } from 'react-redux';

import Row from './CollapsibleTableRow';
import RowSkeleton from './CollapsibleRowLoading';

import styled from '@emotion/styled'
import { useState } from 'react';

const TableRowStyle = styled(TableRow)`
    .last-tr{
       border-right: none;
    }
`;

const TableCellRow = styled(TableCell)`
    border-right: 1px solid #A3A3A3;
    padding: 0 1em;
`;

const TableHeader = styled(TableCell)`
    background-color: #D5E7F7;
    border-right: 1px solid #A3A3A3;
    padding: 0 1em;
`;

const iterate5Times = [...Array(5).keys()];

export default function CollapsibleTable({ productList, isFirstLoad, isProductsLoading }) {
    const [open, setOpen] = useState(true);

    if (isFirstLoad) {
        return (
            <>
                <TableContainer component={Paper} sx={{ height: [ '300px', '335px', '450px'] }}>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRowStyle>
                                <TableHeader>
                                    {
                                        productList.length > 0 ? 
                                            <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpen(!open)}
                                        >
                                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton> : <></>
                                    }
                                </TableHeader>
                                <TableHeader>Order Number</TableHeader>
                            <TableHeader className='last-tr'>Dispatch</TableHeader>
                        </TableRowStyle>
                        </TableHead>
                    </Table>
                </TableContainer>
            </>
        )
    }

    if (productList.length === 0) {
        return (
            <>
                <TableContainer component={Paper} sx={{ height: [ '300px', '335px', '450px'] }}>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRowStyle>
                                <TableHeader>
                                    {
                                        productList.length > 0 ? 
                                            <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpen(!open)}
                                        >
                                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton> : <></>
                                    }
                                </TableHeader>
                                <TableHeader>Order Number</TableHeader>
                            <TableHeader className='last-tr'>Dispatch</TableHeader>
                        </TableRowStyle>
                        </TableHead>
                        <TableBody>
                            <TableRowStyle sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCellRow colSpan={3} align='center'>{ 'No orders found for the selected route and date.' }</TableCellRow>
                            </TableRowStyle>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
    
    // sx={{ height: [ '725px', '790px', '720px', '935px', '495px'] }}
    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                        <TableRowStyle>
                            <TableHeader>
                                {
                                    productList.length > 0 ? 
                                        <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpen(!open)}
                                    >
                                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    </IconButton> : <></>
                                }
                            </TableHeader>
                            <TableHeader>Order Number</TableHeader>
                            <TableHeader className='last-tr'>Dispatch</TableHeader>
                        </TableRowStyle>
                    </TableHead>
                    <TableBody>
                        {
                            isProductsLoading ?
                                iterate5Times.map((item) => (
                                    <RowSkeleton key={'sr' + item}/>
                                )):
                                productList.map((row) => (
                                    <Row
                                        key={row.orderNumber1}
                                        row={row}
                                        openOverride={open}
                                    />
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

/** SAVE FOR LATER
 * 
 *  RETURNS 
 * 
 * {
        <Stack direction='row' justifyContent='end' mt={1}>
            <Button
                variant="contained"
                size='large'
                sx={{ width: '25%' }}
                disabled
            >Update</Button>
        </Stack> 
    }
 * 
 */