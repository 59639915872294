import {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';

import {
    Dialog,
    DialogTitle,
    Box,
    IconButton,
    Typography,
} from '@mui/material';

import HistoryCollapsibleTable from '../../components/OrderHistory/HistoryCollapsibleTable';

import {getTicketHistory} from '../../services/orderModalAPICalls';
import {resetHistoryData} from '../../store/slices/orderSlice'

import { Close } from '@mui/icons-material';

import styled from '@emotion/styled';

const ModalHeaderText = styled(Typography)`
    fontWeight: bold;
`;

export default function OrderHistorytModal({ isOpen, handleClose }) {
    const dispatch = useDispatch();
    const { 
        selectedSKUInfo, 
        ticketHistory,
        isLoading,
    } = useSelector((state) => state.order);

    useEffect(() => {
        getTicketHistory(
            selectedSKUInfo.orderNum,
            selectedSKUInfo.sku,
            selectedSKUInfo.division,
            dispatch
        );
    }, []);

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth='95vw'
                sx={{
                    marginTop: '2em',
                    marginBottom: '0'
                }}
            >
                <DialogTitle>
                    <ModalHeaderText variant="h3">
                        {`SKU# ${selectedSKUInfo.sku} Ticket History`}
                    </ModalHeaderText>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Box
                    sx={{
                        width: '98%',
                        minHeight: ['40em'],
                        margin: '0 auto'
                    }}>
                    <HistoryCollapsibleTable
                        historyData={ticketHistory} 
                        isProductsLoading={isLoading}
                    />
                </Box>
            </Dialog>
        </>
    )
}