import {
    Box,
    Collapse,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Skeleton,
} from '@mui/material';

import styled from '@emotion/styled'

const TableRowStyle = styled(TableRow)`
    .last-tr{
       border-right: none;
    }
`;

const TableHeader = styled(TableCell)`
    background-color: #D5E7F7;
    border-right: 1px solid #A3A3A3;
`;

const TableCellRow = styled(TableCell)`
    border-right: 1px solid #A3A3A3;
    padding: 16px 6px;
`;

const iterate5Times = [...Array(5).keys()];

export default function RowSkeleton({key}) {
    return (
        <>
            <TableRowStyle
                key={key}
                sx={{ '& > *': { borderBottom: 'unset' } }}
            >
                <TableCellRow>
                    <Skeleton variant='rectangular' height={34} />
                </TableCellRow>
                <TableCellRow component="th" scope="row">
                    <Skeleton variant='rectangular' height={34} />
                </TableCellRow>
                <TableCell>
                    <Skeleton variant='rectangular' height={34} />
                </TableCell>
            </TableRowStyle>
            <TableRow>
                <TableCell style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    backgroundColor: "#e9e9e9"
                }} colSpan={6}>
                    <Collapse in={true} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="associated SKUs">
                                    <TableHead>
                                        <TableRowStyle>
                                            <TableHeader>Qty</TableHeader>
                                            <TableHeader>SKU</TableHeader>
                                            <TableHeader>Description</TableHeader>
                                            <TableHeader>Dispatch</TableHeader>
                                            <TableHeader className='last-tr' align='center'>Returned</TableHeader>
                                        </TableRowStyle>
                                    </TableHead>
                                    <TableBody>
                                        {iterate5Times.map((item) => (
                                            <TableRow key={'sr_child' + item}>
                                                <TableCellRow component="th" scope="row">
                                                    <Skeleton variant='rectangular' height={24} />
                                                </TableCellRow>
                                                <TableCellRow>
                                                    <Skeleton variant='rectangular' height={24} />
                                                </TableCellRow>
                                                <TableCellRow>
                                                    <Skeleton variant='rectangular' height={24} />
                                                </TableCellRow>
                                                <TableCellRow>
                                                    <Skeleton variant='rectangular' height={24} />
                                                </TableCellRow>
                                                <TableCell align='center'>
                                                    <Skeleton variant='rectangular' height={24} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}