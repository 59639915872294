import { v4 } from "uuid";
import _ from 'lodash';

function RegionCheck(region) {
    return _.isNil(region) ? 'FL' : region;
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const msftConstants = {
    minDateTime: new Date('0001-01-01T00:00:00Z'),
    maxDateTime: new Date('9999-12-31T23:59:59.9999999Z')
}

function PriceFormatter(price) {
    return _.isNil(price) ? formatter.format(0) : formatter.format(price);
}

function OIRecordsReturnLineItems(oiRecs) {
    const recsOut = oiRecs.map(o => {
        const itProtectionDefault = (protection, esc, status) => {
            const protectionUnitRetail = protection.UnitRetail ?? 0;
            const warrantyUnitRetail = esc.UnitRetail ?? 0;

            if (protectionUnitRetail !== 0) {
                return (status !== 2) ? "Y" : "";
            }
            else if (warrantyUnitRetail > 0.01 && status !== 2) {
                const provider = esc.Provider ? esc.Provider : "G";
                return (
                    esc.ExpirationDate !== null
                    && esc.ExpirationDate !== msftConstants.minDateTime
                    && esc.ExpirationDate <= Date.now())
                    ? `X - ${provider}` : provider
            }
            
        }

        const itProtectionExt = (protection, esc, orderQuantity) => {
            if (protection.UnitRetail && protection.UnitRetail !== 0) {
                return PriceFormatter(protection.UnitRetail * orderQuantity);
            }
            else if (esc.UnitRetail && esc.UnitRetail > 0.01) {
                return PriceFormatter(esc.UnitRetail * orderQuantity);
            }

            return '';
        }

        const lineDateOut = (date) => {
            if (date === null)
                return '';
            if (date.toLowerCase() === 'discontinued')
                return 'Discontinued';
            
            const dateCheck = new Date(date);
            if (dateCheck.getFullYear() === null)
                return 'Available';
            if (dateCheck.getFullYear() === 9999)
                return 'Discontinued';
            if (dateCheck.getFullYear() === 2999)
                return 'Inactive';
            
            return new Date(date).toLocaleDateString('en-us')
        }

        const statusOut = (status) => {
            switch (status) {
                case 0:
                    return "Empty";
                case 1:
                    return "BeingInvoiced";
                case 2:
                    return "Canceled";
                case 3:
                    return "Invoiced";
                case 4:
                    return "Noticed";
                case 5:
                    return "Open";
                case 6:
                    return "Redelivery";
                case 7:
                    return "Picked";
                case 8:
                    return "Other";
                default:
                    return "";
            }
        }

        const ExtPriceChecker = (indicator, pkgNumber, retailVal) => {
            if (indicator.toLowerCase() === 'pkg' || pkgNumber === '') {
                return PriceFormatter(retailVal);
            }

            return '';
        }

        return {
            lineNum: o.LineNumber,
            skuNum: o.SkuNumber,
            vendor: o.VendorCode,
            description: o.Description,
            itProtection: itProtectionDefault(o.Protection, o.ESC, o.Status),
            extProtection: itProtectionExt(o.Protection, o.ESC, o.OrderQuantity),
            status: statusOut(o.Status),
            date: lineDateOut(o.PickDate),
            wh: o.UnitSalesCommission,
            qty: o.OrderQuantity,
            unitPrice: PriceFormatter(o.UnitRetail),
            extPrice: ExtPriceChecker(o.PackageIndicator, o.PackageNumber, o.ExtendedRetail),
        }
    });

    return recsOut;
}

function PaymentTypeItems(deposits) {
    const paymentOut = deposits.map(p => {
        const returnAuthNumber = (cpkey) => {
            const authNumArray = cpkey.split('*');
            return authNumArray[2];
        }
        const payDate = new Date(p.Date).toLocaleDateString('en-us');

        return {
            paymentType: p.CashCode,
            amt: p.Amount,
            date: payDate,
            authNum: returnAuthNumber(p.CPKey)
        }
    });

    return paymentOut;
}

function OutputOrderResult(orders) {
    const orderOutput = {
            orderInfo: {
                orderNumber: orders.OrderNumber,
                homeNumber: orders.CMRecord.PhoneNumber,
                altNumber: orders.CMRecord.AlternatePhone,
                name: orders.CMRecord.Name.LastFirst,
                address: `${orders.CMRecord.DeliveryAddress.Address1}, ${orders.CMRecord.DeliveryAddress.CSZ}`,
                specialInstructions: orders.OMRecord.SpecialInstructions,
                deliveryType: orders.OMRecord.DeliveryType,
                deliveryCharge: orders.OMRecord.DeliveryCharge,
                date: new Date(orders.OMRecord.CreateDate).toLocaleDateString('en-us'),
                salesAssoc: orders.OMRecord.SalespersonNumber,
                taxCode: orders.CMRecord.TaxCode,
                store: orders.OMRecord.StoreNumber
            },
            lineItems: OIRecordsReturnLineItems(orders.OIRecords),
            payments: PaymentTypeItems(orders.OMRecord.Deposits),
            paymentInfo: {
                salesTotal: PriceFormatter(orders.SalesTotal),
                deliveryCharge: PriceFormatter(orders.OMRecord.DeliveryCharge),
                subTotal: PriceFormatter(orders.SubTotal),
                salesTax: PriceFormatter(orders.Tax.CombinedTaxAmount),
                orderTotal: PriceFormatter(orders.OrderTotal),
                deposit: PriceFormatter(orders.DepositTotal),
                collected: PriceFormatter(orders.CollectedAmount),
                balance: PriceFormatter(orders.BalanceDue)
            }
    };
    
    return orderOutput;
}

function OutputOrderNotes(notes) {
    const lastIndex = notes.length - 1;
    const notesOutput = notes.map((n, i) => {
        return {
            index: i,
            key: v4(),
            title: n.Header,
            date: n.TimeStamp !== null ? new Date(n.TimeStamp).toLocaleDateString('en-us') : '',
            note: n.Content,
            isLast: i === lastIndex ? true : false
        }
    });

    return notesOutput;
}

function IsObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}

function IsArrayEmpty(arr) {
    return arr.length === 0;
}

function getDateMinus21() {
    const daysToAdd = process.env.REACT_APP_MOVEMENTRANGE;

    console.log(daysToAdd)
    const date = new Date();
    const date21 = new Date();
    date21.setDate(date.getDate() - daysToAdd);

    return date21;
}

export {
    RegionCheck,
    OutputOrderResult,
    OutputOrderNotes,
    IsObjectEmpty,
    IsArrayEmpty,
    getDateMinus21,
}