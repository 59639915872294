
import PropTypes from 'prop-types';

export default function CellLink({ children, href }) {
    return (
        <a
            target="_blank"
            rel='noreferrer'
            href={href}
            underline='none'
            sx={{
                display: 'flex',
                alignItems:'end'
            }}
        >
            {children}
        </a>
    )
};

CellLink.propTypes = {
    href: PropTypes.string
}