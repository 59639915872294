const data = [
    {
        "orderNumber1": 1,
        "salesOrderNumber": "18035218A",
        "routeData": [
            {
                "orderNumber2": 101,
                "quantityExpected": 1,
                "SKU": "SYNEA.11521716",
                "vendorDescription": "2179-85PHR DUAL PWR RECL",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 2,
        "salesOrderNumber": "29012282",
        "routeData": [
            {
                "orderNumber2": 201,
                "quantityExpected": 1,
                "SKU": "MANWA.15119010",
                "vendorDescription": "RUS90054HM-L2.5-2EH DUAL PWR RECL SOFA",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 3,
        "salesOrderNumber": "29019275",
        "routeData": [
            {
                "orderNumber2": 301,
                "quantityExpected": 1,
                "SKU": "TEPPA.50307365",
                "vendorDescription": "FL PROADAPT FIRM",
                "orderQuantity": 1,
                "acceptQuantity": 0,
                "rejectQuantity": 1
            },
            {
                "orderNumber2": 302,
                "quantityExpected": 1,
                "SKU": "SELYA.50418601",
                "vendorDescription": "FL EASE 4.0",
                "orderQuantity": 1,
                "acceptQuantity": 0,
                "rejectQuantity": 1
            },
            {
                "orderNumber2": 303,
                "quantityExpected": 1,
                "SKU": "TEPPA.50307365",
                "vendorDescription": "FL PROADAPT FIRM",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 304,
                "quantityExpected": 1,
                "SKU": "SELYA.50418601",
                "vendorDescription": "FL EASE 4.0",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 4,
        "salesOrderNumber": "29019318",
        "routeData": [
            {
                "orderNumber2": 401,
                "quantityExpected": 1,
                "SKU": "BEDGA.51010622",
                "vendorDescription": "FULL VER-TEX PROTECTOR",
                "orderQuantity": 1,
                "acceptQuantity": 0,
                "rejectQuantity": 1
            },
            {
                "orderNumber2": 402,
                "quantityExpected": 1,
                "SKU": "TEPPA.59953022",
                "vendorDescription": "TEMPUR-ADAPT PRO-LO KG PLW",
                "orderQuantity": 1,
                "acceptQuantity": 0,
                "rejectQuantity": 1
            },
            {
                "orderNumber2": 403,
                "quantityExpected": 1,
                "SKU": "BEDGA.51010622",
                "vendorDescription": "FULL VER-TEX PROTECTOR",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 404,
                "quantityExpected": 1,
                "SKU": "TEPPA.59953022",
                "vendorDescription": "TEMPUR-ADAPT PRO-LO KG PLW",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 5,
        "salesOrderNumber": "29071181I",
        "routeData": [
            {
                "orderNumber2": 501,
                "quantityExpected": 1,
                "SKU": "JFRNA.43217074",
                "vendorDescription": "1707-72FTRTG TABLE TOP",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 502,
                "quantityExpected": 1,
                "SKU": "JFRNA.43317076",
                "vendorDescription": "1707-72BSRTG TABLE BASE",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 503,
                "quantityExpected": 4,
                "SKU": "JFRNA.43417078",
                "vendorDescription": "1707-BS401RTG CNT HGT STOOL",
                "orderQuantity": 4,
                "acceptQuantity": 4,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 504,
                "quantityExpected": 1,
                "SKU": "JFRNA.43517070",
                "vendorDescription": "1707-58RTG CNTR HT BENCH",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 505,
                "quantityExpected": 3,
                "SKU": "JFRNA.43617072",
                "vendorDescription": "1707-18RTG CNTR HT KYOTO STOOL",
                "orderQuantity": 3,
                "acceptQuantity": 3,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 506,
                "quantityExpected": 1,
                "SKU": "JFRNA.43717074",
                "vendorDescription": "1707-35RTG SERVER",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 6,
        "salesOrderNumber": "29170456I",
        "routeData": [
            {
                "orderNumber2": 601,
                "quantityExpected": 1,
                "SKU": "LFSTA.32621965",
                "vendorDescription": "2196-030 CHEST",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 7,
        "salesOrderNumber": "29197240",
        "routeData": [
            {
                "orderNumber2": 701,
                "quantityExpected": 1,
                "SKU": "LFSTA.32621965",
                "vendorDescription": "2196-030 CHEST",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 8,
        "salesOrderNumber": "29317694",
        "routeData": [
            {
                "orderNumber2": 801,
                "quantityExpected": 1,
                "SKU": "SMNSA.50736097",
                "vendorDescription": "KG C-CLASS PS PT",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 802,
                "quantityExpected": 1,
                "SKU": "MISCA.59999977",
                "vendorDescription": "NO FOUNDATION",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 803,
                "quantityExpected": 1,
                "SKU": "BEDGA.59906287",
                "vendorDescription": "COSMO 1.0 KING PILLOW",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 804,
                "quantityExpected": 1,
                "SKU": "BEDGA.51010545",
                "vendorDescription": "KING DRI-TEC PROTECTOR",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 9,
        "salesOrderNumber": "29317761",
        "routeData": [
            {
                "orderNumber2": 901,
                "quantityExpected": 1,
                "SKU": "BEDGA.59906287",
                "vendorDescription": "COSMO 1.0 KING PILLOW",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 10,
        "salesOrderNumber": "29325894",
        "routeData": [
            {
                "orderNumber2": 1001,
                "quantityExpected": 1,
                "SKU": "CRAMA.42052605",
                "vendorDescription": "5260-62 TABLE TOP",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1002,
                "quantityExpected": 1,
                "SKU": "CRAMA.43352602",
                "vendorDescription": "5260-47 COUNTER HT TABLE COLUMN",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1003,
                "quantityExpected": 1,
                "SKU": "CRAMA.42252609",
                "vendorDescription": "5260-50 TABLE BASE",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1004,
                "quantityExpected": 4,
                "SKU": "CRAMA.43452604",
                "vendorDescription": "5260-25 COUNTER STOOL",
                "orderQuantity": 4,
                "acceptQuantity": 4,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1005,
                "quantityExpected": 2,
                "SKU": "CRAMA.43452604",
                "vendorDescription": "5260-25 COUNTER STOOL",
                "orderQuantity": 2,
                "acceptQuantity": 2,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 11,
        "salesOrderNumber": "29330148",
        "routeData": [
            {
                "orderNumber2": 1101,
                "quantityExpected": 1,
                "SKU": "MAAHA.17601382",
                "vendorDescription": "RU70137HM HC(H) CONSOLE",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 12,
        "salesOrderNumber": "29330826",
        "routeData": [
            {
                "orderNumber2": 1201,
                "quantityExpected": 1,
                "SKU": "SYNEA.11505813",
                "vendorDescription": "5021-85PHR DUAL PWR RECLINER",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1202,
                "quantityExpected": 1,
                "SKU": "NCHFA.42122705",
                "vendorDescription": "1702-10 DINING TABLE",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1203,
                "quantityExpected": 4,
                "SKU": "NCHFA.42422701",
                "vendorDescription": "1702-20 SIDE CHAIR",
                "orderQuantity": 4,
                "acceptQuantity": 4,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 13,
        "salesOrderNumber": "29339036",
        "routeData": [
            {
                "orderNumber2": 1301,
                "quantityExpected": 1,
                "SKU": "SERMA.50513401",
                "vendorDescription": "QN CF1000C QUILTED II FIRM",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1302,
                "quantityExpected": 1,
                "SKU": "MISCA.59999977",
                "vendorDescription": "NO FOUNDATION",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 14,
        "salesOrderNumber": "29339055",
        "routeData": [
            {
                "orderNumber2": 1401,
                "quantityExpected": 2,
                "SKU": "ERSLA.59940001",
                "vendorDescription": "CASPER PILLOW STANDARD",
                "orderQuantity": 2,
                "acceptQuantity": 2,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1402,
                "quantityExpected": 1,
                "SKU": "BEDGA.51010242",
                "vendorDescription": "QN IPROTECT PROTECTOR",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 15,
        "salesOrderNumber": "29342023",
        "routeData": [
            {
                "orderNumber2": 1501,
                "quantityExpected": 1,
                "SKU": "KNGSA.50182674",
                "vendorDescription": "TW ELLISDALE",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1502,
                "quantityExpected": 1,
                "SKU": "KNGSA.50283476",
                "vendorDescription": "TWIN HI PRO FOUNDATION",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1503,
                "quantityExpected": 1,
                "SKU": "KNGSA.50182674",
                "vendorDescription": "TW ELLISDALE",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1504,
                "quantityExpected": 1,
                "SKU": "KNGSA.51283477",
                "vendorDescription": "TWIN LO PRO FOUNDATION",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1505,
                "quantityExpected": 2,
                "SKU": "BEDGA.59906249",
                "vendorDescription": "COSMO 1.0 PILLOW",
                "orderQuantity": 2,
                "acceptQuantity": 2,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1506,
                "quantityExpected": 1,
                "SKU": "BEDGA.51010228",
                "vendorDescription": "TW IPROTECT PROTECTOR",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1507,
                "quantityExpected": 1,
                "SKU": "BEDGA.51010507",
                "vendorDescription": "TWIN DRI-TEC PROTECTOR",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 16,
        "salesOrderNumber": "29343153",
        "routeData": [
            {
                "orderNumber2": 1601,
                "quantityExpected": 1,
                "SKU": "GLIDA.59951004",
                "vendorDescription": "4 LEG T/F FRAME W/ GLIDES",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1602,
                "quantityExpected": 1,
                "SKU": "BEDGA.51010519",
                "vendorDescription": "TWXL DRI-TEC PROTECTOR",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1603,
                "quantityExpected": 1,
                "SKU": "SLEPA.50014465",
                "vendorDescription": "TWXL CAPELLA",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1604,
                "quantityExpected": 1,
                "SKU": "SLEPA.50814500",
                "vendorDescription": "TWX HI PRO FOUNDATION",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 17,
        "salesOrderNumber": "29343244",
        "routeData": [
            {
                "orderNumber2": 1701,
                "quantityExpected": 1,
                "SKU": "SMNSA.50736136",
                "vendorDescription": "KG LX-CLASS MD TT",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 1702,
                "quantityExpected": 1,
                "SKU": "MISCA.59999977",
                "vendorDescription": "NO FOUNDATION",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 18,
        "salesOrderNumber": "29343255",
        "routeData": [
            {
                "orderNumber2": 1801,
                "quantityExpected": 1,
                "SKU": "LFSTA.33721920",
                "vendorDescription": "2192-QTG Q STG FTBD W/ SLATS",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 19,
        "salesOrderNumber": "29343310",
        "routeData": [
            {
                "orderNumber2": 1901,
                "quantityExpected": 2,
                "SKU": "BEDGA.59906251",
                "vendorDescription": "COSMO 2.0 PILLOW",
                "orderQuantity": 2,
                "acceptQuantity": 2,
                "rejectQuantity": 0
            }
        ]
    },
    {
        "orderNumber1": 20,
        "salesOrderNumber": "29343395",
        "routeData": [
            {
                "orderNumber2": 2001,
                "quantityExpected": 1,
                "SKU": "SERMA.50513413",
                "vendorDescription": "QN CF2000C QUILTED II PS",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 2002,
                "quantityExpected": 1,
                "SKU": "FGRPA.50608870",
                "vendorDescription": "QN RTG-SLEEP 4100",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            },
            {
                "orderNumber2": 2003,
                "quantityExpected": 1,
                "SKU": "BEDGA.51010533",
                "vendorDescription": "QUEEN DRI-TEC PROTECTOR",
                "orderQuantity": 1,
                "acceptQuantity": 1,
                "rejectQuantity": 0
            }
        ]
    }
];

const addSkus = [
    {
        'id': 'addsku1',
        'sku': 'AddSKU1',
        'description': 'Test SKU Description',
    },
    {
        'id': 'addsku2',
        'sku': 'AddSKU2',
        'description': 'Test SKU Description',
    },
    {
        'id': 'addsku3',
        'sku': 'AddSKU3',
        'description': 'Test SKU Description',
    },
    {
        'id': 'addsku4',
        'sku': 'AddSKU4',
        'description': 'Test SKU Description',
    }
];
    
export {
    data,
    addSkus,
};