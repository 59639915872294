
import {
    Box,
    TextField,
    Paper,
    InputBase,
    IconButton,
    Typography,
} from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { P2 } from './Typography';

import dayjs from 'dayjs';
import styled from '@emotion/styled'

import PropTypes from 'prop-types';
const P2Item = styled(Typography)`
    margin-right: 1em;
`;

function InputField({ labelText, placeholder, value, setValue, isInline = true}) {
    if (isInline) {
        
        return (
            <Box
                display='flex'
                alignItems='baseline'
                justifyContent='space-evenly'
                className='input-field-container'
            >
                <P2Item variant="body2">{labelText}</P2Item>
                <TextField 
                    hiddenLabel 
                    placeholder={placeholder} 
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    variant="outlined" 
                    inputProps={{ maxLength: 4 }}
                    sx={{
                        width:'4em'
                    }}
                />
            </Box>
        )
    }

    return (
        <Box className='input-field-container'>
            <P2>{labelText}</P2>
            <TextField 
                hiddenLabel 
                placeholder={placeholder} 
                value={value}
                onChange={e => setValue(e.target.value)}
                variant="outlined" 
            />
        </Box>
    )
}

InputField.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    labelText: PropTypes.string,
    placeholder: PropTypes.string,
}

function ImputFieldNoLabel ({ placeholder, setInputFn }) {
    return (
        <Box className='input-field-container'>
            <TextField 
                hiddenLabel 
                placeholder={placeholder} 
                variant="outlined" 
                onChange={e => setInputFn(e.target.value)}
            />
        </Box>
    )
}

ImputFieldNoLabel.propTypes = {
    placeholder: PropTypes.string,
    setInputFn: PropTypes.func
}

function DateField({ labelText, defaultVal, value, setValue, isInline = true }) {

    if (isInline) {
        return (
            <Box
                display='flex'
                alignItems='baseline'
                justifyContent='space-evenly'
                className='input-field-container'
            >
                <P2Item variant="body2">{labelText}</P2Item>
                <DatePicker
                    value={defaultVal ? dayjs(defaultVal) : value}
                    onChange={(newValue) => setValue(newValue)} 
                    size="small"
                    sx={{
                        width: ['8em', '8em', '8em', '8em', '11em']
                    }}
                />
            </Box>
        )
    }

    return(
        <Box className='input-field-container'>
            <P2>{labelText}</P2>
            <DatePicker
                value={defaultVal ? dayjs(defaultVal) : value}
                onChange={(newValue) => setValue(newValue)} 
                size="small"
            />
        </Box>
    )
}

DateField.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    labelText: PropTypes.string,
}

function NumberField({ labelText, placeholder, value, setValue, isInline = true}) {
    if (isInline) {
        
        return (
            <Box
                display='flex'
                alignItems='baseline'
                justifyContent='space-evenly'
                className='input-field-container'
            >
                <P2Item variant="body2">{labelText}</P2Item>
                <TextField 
                    hiddenLabel 
                    placeholder={placeholder} 
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    variant="outlined" 
                    type="number"
                    inputProps={{
                        maxLength: 4,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    sx={{
                        width:'4.75em'
                    }}
                />
            </Box>
        )
    }

    return (
        <Box className='input-field-container'>
            <P2>{labelText}</P2>
            <TextField 
                hiddenLabel 
                placeholder={placeholder} 
                value={value}
                onChange={e => setValue(e.target.value)}
                variant="outlined" 
                type="number"
                inputProps={{
                    maxLength: 4,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                }}
            />
        </Box>
    )
}

NumberField.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    labelText: PropTypes.string,
    placeholder: PropTypes.string,
}

function SearchField() {
    return (
        <Paper
        component="form"
        sx={{ display: 'flex', alignItems: 'center', width: 215, border: '1px solid #D9D9D9', boxShadow: 'none'}}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                inputProps={{ 'aria-label': '' }}
                variant="outlined" 
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchOutlined />
            </IconButton>
        </Paper>
    )
}

export {
    InputField,
    ImputFieldNoLabel,
    DateField,
    SearchField,
    NumberField,
};