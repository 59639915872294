
import App from '../App';

import { AuthenticatedTemplate } from "@azure/msal-react";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../features/Authentification/authProvider';

import { store } from '../store/store';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import theme from '../components/HelperComponents/Theme';

export default function Homepage() {

    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();

    if (inProgress === 'none' && !isAuthenticated) {
        instance.loginRedirect(loginRequest);
    }
    
    return (
        <AuthenticatedTemplate>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </LocalizationProvider>
            </Provider>
        </AuthenticatedTemplate>
    )
}