import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Popover,
    Typography,
    ButtonGroup,
    Button,
    Tooltip,
    //Checkbox,
} from '@mui/material';
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    SwapHorizontalCircle,
    History,
} from '@mui/icons-material';

import {
    setParams,
    resetOrderDetails,
    setSelectedSku,
    setTicketHistory,
    resetSelectedSku,
    resetMovementData,
    resetHistoryData,
} from '../../store/slices/orderSlice';

import { yellow } from '@mui/material/colors';

//import { useDispatch } from 'react-redux';
//import { updateSkuReturned } from '../../store/slices/productSlice'; 

import styled from '@emotion/styled';
import {
    RegionCheck,
    getDateMinus21,
} from '../../utils/helpers';

import {
    CellCardLink,
    CellCardButton,
} from './CellItems/CellCardLink';
import CellLink from './CellItems/CellLink';
import ImgPopover from './CellItems/ImagePopover';
import ImageModal from './CellItems/ImageModal';
import OrderModal from '../../features/OrderModal/OrderModal';
import OrderMovementModal from '../../features/OrderMovementModal/OrderMovement';
import OrderHistorytModal from '../../features/OrderHistoryModal/OrderHistory';

const TableRowStyle = styled(TableRow)`
    .last-tr{
       border-right: none;
       border-bottom: none;
    }
`;

const TableHeader = styled(TableCell)`
    background-color: #D5E7F7;
    border-right: 1px solid #A3A3A3;
    padding:0 1em;
`;

const TableCellRow = styled(TableCell)`
    border-right: 1px solid #A3A3A3;
    padding: 0 1em;
`;

const TableCellRowChild = styled(TableCell)`
    border-right: 1px solid #A3A3A3;
    padding: 0 1em;
    font-size: 1.15em;
`;

function outputImgUrl(assetId, profile) {
    if (assetId) return `https://caas-content.rtgbizapps.com/rendition?id=${assetId}`;
    if (profile) return `https://caas-content.rtgbizapps.com/rendition?id=${assetId}&prid=${profile}`;
    return '././Content/images/img-default.gif';
}

/** SAVE FOR LATER
 * <Checkbox
        checked={sku.checked}
        onChange={() => dispatch(updateSkuReturned({ rowId: row.OrderNumber1, skuId: sku.OrderNumber2 }))}
    />

    ---TableRow Items - RETURNS

    <TableHeader className='last-tr' align='center'>Returned</TableHeader>
    <TableCellRowChild align='center'></TableCellRowChild>
 */

function shopRepairCheck(orderNum) {
    return orderNum.toLowerCase() === 'r';
}

function NormalRow({ children }) {
    return <TableRowStyle
        sx={{
            '& > *': { borderBottom: 'unset' },
        }}
    >
        {children}
    </TableRowStyle>;
}

function HighlightedRow({ children, handlePopoverOpen, handlePopoverClose }) {
    return <TableRowStyle
        sx={{
            '& > *': { borderBottom: 'unset' },
            backgroundColor: yellow[50]
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
    >
        {children}
    </TableRowStyle>;
}

export default function CollapsibleRow({ row, openOverride }) {
    const dispatch = useDispatch();
    const savedRegion = useSelector((state) => state.order.region);
    const savedParams = useSelector((state) => state.product.previousSearchValues);

    const [open, setOpen] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [storedModalInfo, setStoredModalInfo] = useState({
        skuNum: '',
        descr: '',
        src: ''
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [openMoveModal, setOpenMoveModal] = useState(false);
    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [divison, setDivison] = useState('FL');

    const handleModalOpen = useCallback((
        sku,
        description,
        imgSrc
    ) => {
        setStoredModalInfo({
            descr: description,
            src: imgSrc
        });
        setModalOpen(true);
    }, []);
    const handleModalClose = useCallback(() => {
        setStoredModalInfo({
            skuNum: '',
            descr: '',
            src: ''
        });
        setModalOpen(false);
    }, []);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);

    const handleOpenDetailsModal = (orderNum) => {
        const region = RegionCheck(savedRegion);
        dispatch(setParams({ orderNum: orderNum, region: region }));
        setOpenDetailsModal(true);
    }
    const handleCloseDetailsModal = () => {
        dispatch(resetOrderDetails());
        setOpenDetailsModal(false);
    }

    const handleOpenMovementModal = (sku, row) => {
        dispatch(resetMovementData());
        dispatch(setSelectedSku({
            orderNum: row.SalesOrderNumber,
            sku: sku.Sku,
            pmkey: sku.PMKey,
            warehouseId: row.WarehouseId,
            division: savedParams.divison,
            startDate: getDateMinus21().toString(),
            endDate: new Date().toString(),
        }));
        setOpenMoveModal(true);
    }

    const handleCloseMovementModal = () => {
        dispatch(resetSelectedSku());
        setOpenMoveModal(false);
    }

    const handleOpenHistoryModal = (sku, row) => {
        dispatch(resetHistoryData());
        dispatch(setSelectedSku({
            orderNum: row.SalesOrderNumber,
            sku: sku.Sku,
            pmkey: sku.PMKey,
            warehouseId: row.WarehouseId,
            division: savedParams.divison,
            startDate: getDateMinus21().toString(),
            endDate: new Date().toString(),
        }));
        setOpenHistoryModal(true);
    }

    const handleCloseHistoryModal = () => {
        dispatch(resetSelectedSku());
        setOpenHistoryModal(false);
    }

    useMemo(() => {
        setOpen(openOverride);
    }, [openOverride])

    useMemo(() => {
        setDivison(savedParams.divison === null ? 'FL' : savedParams.divison)
    }, [savedParams])

    return (
        <React.Fragment>
            {
                shopRepairCheck(row.SalesOrderNumber[0]) ?
                    <HighlightedRow
                        handlePopoverOpen={handlePopoverOpen}
                        handlePopoverClose={handlePopoverClose}
                    >
                        <TableCellRow>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        </TableCellRow>
                        <TableCellRow component="th" scope="row">
                            <CellCardButton
                                modalFn={() => handleOpenDetailsModal(row.SalesOrderNumber)}
                            >
                                {row.SalesOrderNumber}
                            </CellCardButton>
                        </TableCellRow>
                        <TableCellRow>
                            <CellCardLink
                                href={process.env.REACT_APP_DTLINK + `service-orders/${divison}${row.SalesOrderNumber}`}
                                code={row.DispatchCodeStatus}
                            >
                                {row.DispatchCode}
                            </CellCardLink>
                        </TableCellRow>
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={popoverOpen}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <Typography sx={{ p: '.5em 2em' }}>{'Shop Repair Order'}</Typography>
                        </Popover>
                    </HighlightedRow> :
                    <NormalRow>
                        <TableCellRow>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        </TableCellRow>
                        <TableCellRow component="th" scope="row">
                            <CellCardButton
                                modalFn={() => handleOpenDetailsModal(row.SalesOrderNumber)}
                            >
                                {row.SalesOrderNumber}
                            </CellCardButton>
                        </TableCellRow>
                        <TableCellRow>
                            <CellCardLink
                                href={process.env.REACT_APP_DTLINK + `service-orders/${divison}${row.SalesOrderNumber}`}
                                code={row.DispatchCodeStatus}
                            >
                                {row.DispatchCode}
                            </CellCardLink>
                        </TableCellRow>
                    </NormalRow>
            }

            <TableRow>
                <TableCell style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    backgroundColor: "#e9e9e9"
                }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="associated SKUs">
                                    <colgroup>
                                        <col style={{ width: '7%' }} />
                                        <col style={{ width: '7%' }} />
                                        <col style={{ width: '11%' }} />
                                        <col style={{ width: '32%' }} />
                                        <col style={{ width: '32%' }} />
                                    </colgroup>
                                    <TableHead>
                                        <TableRowStyle>
                                            <TableHeader align="center">Accepted Qty</TableHeader>
                                            <TableHeader align="center">Actions</TableHeader>
                                            <TableHeader>SKU</TableHeader>
                                            <TableHeader>Description</TableHeader>
                                            <TableHeader>Reference Color/Finish</TableHeader>
                                            <TableHeader>Dispatch</TableHeader>
                                        </TableRowStyle>
                                    </TableHead>
                                    <TableBody>
                                        {row.RouteData.map((sku) => (
                                            <TableRow key={sku.OrderNumber2}>
                                                <TableCellRowChild component="th" scope="row" align="center">{sku.AcceptQuantity}</TableCellRowChild>
                                                <TableCellRowChild align="center">
                                                    <ButtonGroup
                                                        size="small"
                                                        variant="text"
                                                        aria-label="sku action group"
                                                    >
                                                        <Tooltip title="Inventory Movement">
                                                            <Button
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    fontSize: 10,
                                                                }}
                                                                onClick={() => handleOpenMovementModal(sku, row)}
                                                            >
                                                                <SwapHorizontalCircle />
                                                                <Box
                                                                    sx={{
                                                                        display: {
                                                                            xs: "block",
                                                                            md: "none",
                                                                        },
                                                                    }}
                                                                >
                                                                    INVT MGMT
                                                                </Box>
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Ticket History">
                                                            <Button
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    fontSize: 10,
                                                                }}
                                                                onClick={() => handleOpenHistoryModal(sku, row)}
                                                            >
                                                                <History />
                                                                <Box
                                                                    sx={{
                                                                        display: {
                                                                            xs: "block",
                                                                            md: "none",
                                                                        },
                                                                    }}
                                                                >
                                                                    TKT HST
                                                                </Box>
                                                            </Button>
                                                        </Tooltip>
                                                    </ButtonGroup>
                                                </TableCellRowChild>
                                                <TableCellRowChild>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly',
                                                        alignItems: 'center'
                                                    }}>
                                                        <CellLink
                                                            href={process.env.REACT_APP_PDMLINK + `Product/Get/${sku.Sku}/${divison}`}
                                                            sku={sku.Sku}
                                                        >
                                                            {sku.Sku}
                                                        </CellLink>
                                                        <ImgPopover
                                                            sku={sku.Sku}
                                                            srcHref={outputImgUrl(sku.AssetId, '')}
                                                            description={sku.VendorDescription}
                                                            modalOpenFn={handleModalOpen}
                                                        />
                                                    </div>
                                                </TableCellRowChild>
                                                <TableCellRowChild>{sku.VendorDescription}</TableCellRowChild>
                                                <TableCellRowChild>{sku.ReferenceColorFinish === '/' ? '' : sku.ReferenceColorFinish}</TableCellRowChild>
                                                <TableCellRowChild>
                                                    {`Qty: ${sku.OrderQuantity ? sku.OrderQuantity : 0} Delivered: ${sku.AcceptQuantity ? sku.AcceptQuantity : 0}`}
                                                </TableCellRowChild>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <ImageModal
                isOpen={modalOpen}
                modalClose={handleModalClose}
                skuNum={storedModalInfo.skuNum}
                description={storedModalInfo.descr}
                imgSrc={storedModalInfo.src}
            />
            {
                openDetailsModal ? <OrderModal
                    isOpen={openDetailsModal}
                    handleClose={handleCloseDetailsModal}
                /> : null
            }
            {
                openMoveModal ? <OrderMovementModal
                    isOpen={openMoveModal}
                    handleClose={handleCloseMovementModal}
                /> : null
            }
            {
                openHistoryModal ? <OrderHistorytModal
                    isOpen={openHistoryModal}
                    handleClose={handleCloseHistoryModal}
                /> : null
            }
        </React.Fragment>
    )
}


CollapsibleRow.propTypes = {
    row: PropTypes.shape({
        OrderNumber1: PropTypes.number.isRequired,
        SalesOrderNumber: PropTypes.string.isRequired,
        DispatchCode: PropTypes.string,
        DispatchCodeStatus: PropTypes.number,
        WarehouseId: PropTypes.string,
        RouteData: PropTypes.arrayOf(
            PropTypes.shape({
                AcceptQuantity: PropTypes.number.isRequired,
                AssetId: PropTypes.string,
                OrderNumber2: PropTypes.number.isRequired,
                OrderQuantity: PropTypes.number.isRequired,
                QuantityExpected: PropTypes.number.isRequired,
                ReferenceColorFinish: PropTypes.string.isRequired,
                RejectQuantity: PropTypes.number.isRequired,
                SalesOrderNumber: PropTypes.string.isRequired,
                Sku: PropTypes.string.isRequired,
                PMKey: PropTypes.string,
                VendorDescription: PropTypes.string.isRequired,
                returned: PropTypes.bool,
            }),
        ).isRequired,
    }).isRequired,
    openOverride: PropTypes.bool.isRequired,
};