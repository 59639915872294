import axios from "axios";
import {
    RegionCheck,
    OutputOrderResult,
    OutputOrderNotes,
} from "../utils/helpers";
import {
    setOrderDetails,
    setOrderNotes,
    setMovementData,
    setRelatedOrders,
    setTicketHistory,
} from "../store/slices/orderSlice";

const headers = {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_CUSTOMERLINK_KEY
};

async function getOrderDetailsByID(orderNum, region, dispatch) {
    const response = await axios.post(
        process.env.REACT_APP_CUSTOMERLINK + "api/Customer/GetCustomerOrderById",
        {
            "Id": orderNum,
            "Login": process.env.REACT_APP_CUSTOMERLINK_LOGIN,
            "Password": process.env.REACT_APP_CUSTOMERLINK_PW,
            "Region": RegionCheck(region)
        },
        {
            headers: headers
        }
    );

    try {
        dispatch(setOrderDetails(OutputOrderResult(response.data.Order)));
    } catch (err) {
        console.log(err)
        throw err;
    }
}

async function getOrderNotesByID(orderNum, region, dispatch) {
    const response = await axios.post(
        process.env.REACT_APP_CUSTOMERLINK + "api/Customer/GetNotesForOrderId",
        {
            "Id": orderNum,
            "Login": process.env.REACT_APP_CUSTOMERLINK_LOGIN,
            "Password": process.env.REACT_APP_CUSTOMERLINK_PW,
            "Region": RegionCheck(region)
        },
        {
            headers: headers
        }
    );

    try {
        dispatch(setOrderNotes(OutputOrderNotes(response.data.Notes)));
    } catch (err) {
        console.log(err)
        throw err;
    }
}

async function getInventoryMovement(
    pmkey,
    warehouseId,
    division,
    startDate,
    endDate,
    dispatch
)  {

    const apiCall = axios.create({
        baseURL: process.env.REACT_APP_API_URL +
            `api/GetInventoryMovement?pmKey=${pmkey}&warehouseId=${warehouseId}&division=${division}&startDate=${startDate}&endDate=${endDate}`,
        headers: headers,
    })

    const response = await apiCall.get();
    try {
        dispatch(setMovementData(response.data.Data));
    } catch (err) {
        console.log(err)
        throw err;
    }
}

async function getRelatedOrders(
    division,
    orderNumber,
    dispatch
)  {

    const dateRange = process.env.REACT_APP_RELATEDRANGE;

    const apiCall = axios.create({
        baseURL: process.env.REACT_APP_API_URL + `api/GetRelatedOrders/${division}/${orderNumber}/${dateRange}`,
        headers: headers,
    })

    const response = await apiCall.get();
    try {
        dispatch(setRelatedOrders(response.data));
    } catch (err) {
        console.log(err)
        throw err;
    }
}

async function getTicketHistory(
    orderNumber,
    sku,
    division,
    dispatch
)  {
    const apiCall = axios.create({
        baseURL: process.env.REACT_APP_API_URL + `api/GetTicketHistory?orderNumber=${orderNumber}&sku=${sku}&division=${division}`,
        headers: headers,
    })

    const response = await apiCall.get();
    try {
        dispatch(setTicketHistory(response.data.Data));
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export {
    getOrderDetailsByID,
    getOrderNotesByID,
    getInventoryMovement,
    getRelatedOrders,
    getTicketHistory,
};