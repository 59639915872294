import {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';

import {
    Dialog,
    DialogTitle,
    Box,
    IconButton,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Skeleton,
} from '@mui/material';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
    getInventoryMovement,
} from '../../services/orderModalAPICalls';
import {resetMovementData} from '../../store/slices/orderSlice'

import { Close } from '@mui/icons-material';

import styled from '@emotion/styled';

const ModalHeaderText = styled(Typography)`
    fontWeight: bold;
`;

const ModalTitleContainer = styled(Box)`
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    z-index:9;
`;

const TableRowStyle = styled(TableRow)`
    .last-tr{
       border-right: none;
    }
`;

const TableHeader = styled(TableCell)`
    padding: 0 1em;
    background-color: #D5E7F7;
    border-right: 1px solid #A3A3A3;
`;

const TableCellRow = styled(TableCell)`
    padding: 0 1em;
    border-right: 1px solid #A3A3A3;
`;

const DatePickerInput = styled(DatePicker)`
    margin: 0 .5em;
`

export default function OrderMovementModal({ isOpen, handleClose }) {
    const dispatch = useDispatch();
    const { 
        selectedSKUInfo, 
        movementData,
        isLoading,
    } = useSelector((state) => state.order);

    const [startDate, setStartDate] = useState(new Date(selectedSKUInfo.startDate));
    const [endDate, setEndDate] = useState(new Date(selectedSKUInfo.endDate))

    const searchByNewDateRange = useCallback(() => {
        dispatch(resetMovementData());
        getInventoryMovement(
            selectedSKUInfo.pmkey,
            selectedSKUInfo.warehouseId,
            selectedSKUInfo.division,
            new Date(startDate).toLocaleDateString('en-us'),
            new Date(endDate).toLocaleDateString('en-us'),
            dispatch
        );
    }, [startDate, endDate]);
    
    useEffect(() => {
        getInventoryMovement(
            selectedSKUInfo.pmkey,
            selectedSKUInfo.warehouseId,
            selectedSKUInfo.division,
            new Date(startDate).toLocaleDateString('en-us'),
            new Date(endDate).toLocaleDateString('en-us'),
            dispatch
        );
    }, []);

    useEffect(() => {
        searchByNewDateRange();
    }, [searchByNewDateRange]);

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth='95vw'
                sx={{
                    marginTop: '2em',
                    marginBottom: '0'
                }}
            >
                <DialogTitle>
                    <ModalTitleContainer>
                        <Box>
                            <ModalHeaderText variant="h3">
                                {`SKU# ${selectedSKUInfo.sku} Inventory Movement`}
                            </ModalHeaderText>
                        </Box>
                        <Box
                            display={'flex'}
                            marginRight={'3em'}
                            alignItems={'baseline'}
                            justifyContent={'space-evenly'}
                        >
                            <ModalHeaderText 
                            variant="h5" 
                                marginX={'1em'}
                            >
                                {`Select Date Range:`}
                            </ModalHeaderText>
                            <DatePickerInput
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                            />
                            {'-'}
                            <DatePickerInput
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                            />
                        </Box>
                    </ModalTitleContainer>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            zIndex:99,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Box
                    sx={{
                        width: '98%',
                        minHeight: ['40em'],
                        margin: '0 auto'
                    }}>
                        {
                        isLoading ? 
                            <TableContainer component={Paper} sx={{ height: ['610px', '605px', '600px', '610px'] }}>
                                <Skeleton
                                    sx={{
                                        height: ['610px', '605px', '600px', '610px'] 
                                    }}
                                />
                            </TableContainer>
                            :
                            <TableContainer component={Paper} sx={{height: ['610px','605px','600px', '610px']}}>
                                <Table sria-label="Order Movement Table" stickyHeader>
                                    <TableHead>
                                        <TableRowStyle>
                                            <TableHeader>SKU</TableHeader>
                                            <TableHeader>Order Number</TableHeader>
                                            <TableHeader>Date Created</TableHeader>
                                            <TableHeader>Customer Name</TableHeader>
                                            <TableHeader>Source WH</TableHeader>
                                            <TableHeader>Destination WH</TableHeader>
                                            <TableHeader>Trans Type</TableHeader>
                                            <TableHeader>Qty on Hand</TableHeader>
                                            <TableHeader>Qty</TableHeader>
                                        </TableRowStyle>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            movementData.map(m => (
                                                <TableRow key={m.key}>
                                                    <TableCellRow>{m.sku}</TableCellRow>
                                                    <TableCellRow>{m.ordernumber}</TableCellRow>
                                                    <TableCellRow>{new Date(m.createdate).toLocaleDateString('en-us')}</TableCellRow>
                                                    <TableCellRow>{m.customername}</TableCellRow>
                                                    <TableCellRow>{m.sourcewarehouse}</TableCellRow>
                                                    <TableCellRow>{m.destinationwarehouse}</TableCellRow>
                                                    <TableCellRow>{m.TransType}</TableCellRow>
                                                    <TableCellRow>{m.quantityonhand}</TableCellRow>
                                                    <TableCellRow>{m.quantity}</TableCellRow>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>  
                            </TableContainer>
                        }
                </Box>
            </Dialog>
        </>
    )
}