import { useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { Popover, Button } from '@mui/material';
import { Image } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

const PopoverItem = styled(Popover)`
    .MuiPaper-elevation{
        overflow: hidden;
    }
`;

export default function ImgPopover({
    sku,
    srcHref,
    description,
    modalOpenFn
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    return (
        <div>
            <Button
                aria-owns='image-popover'
                aria-haspopup='true'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                onClick={() => {
                    setAnchorEl(null);
                    modalOpenFn(
                        sku,
                        description,
                        srcHref
                    );
                }}
            >
                <Image color='primary'/>
            </Button>
            <PopoverItem
                id='image-popover'
                sx={{
                    pointerEvents: 'none',
                    height: '40%',
                    width: '40%',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        backgroundColor: grey[50]
                    }}
                >
                    <img
                        src={srcHref}
                        alt={description}
                        style={{ 
                            objectFit: 'cover',
                            width: '40%',
                            height: 'auto',
                            padding: '8px'
                        }}
                    />
                </div>
                
            </PopoverItem>
        </div>
    )
}

ImgPopover.propTypes = {
    sku: PropTypes.string,
    srcHref: PropTypes.string,
    description: PropTypes.string,
    modalOpenFn: PropTypes.func
}