import React from 'react';
import {  v4 as uuidv4 } from 'uuid';

import { useSelector } from 'react-redux';

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
    IconButton,
} from '@mui/material';
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
} from '@mui/icons-material';

import HistoryTableRow from './HistoryRow';
import HistoryRowSkeleton from './HistoryTableLoading';

import styled from '@emotion/styled'
import { useState } from 'react';

const TableRowStyle = styled(TableRow)`
    .last-tr{
       border-right: none;
    }
`;

const TableCellRow = styled(TableCell)`
    border-right: 1px solid #A3A3A3;
    padding: 0 1em;
`;

const TableHeader = styled(TableCell)`
    background-color: #D5E7F7;
    border-right: 1px solid #A3A3A3;
    padding: 0 1em;
`;

const TableCellRowChild = styled(TableCell)`
    border-right: 1px solid #A3A3A3;
    padding: 0 1em;
    font-size: 1.15em;
`;

const iterate5Times = [...Array(5).keys()];

export default function HistoryCollapsibleTable({ historyData, isProductsLoading }) {
    const [open, setOpen] = useState(false);

    if (historyData.length === 0) {
        return (
            <>
                <TableContainer component={Paper} sx={{ height: [ '300px', '335px', '450px'] }}>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRowStyle>
                                <TableHeader>
                                {
                                    historyData.length > 0 ? 
                                        <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpen(!open)}
                                    >
                                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    </IconButton> : <></>
                                }
                                </TableHeader>
                                <TableHeader>Order Number</TableHeader>
                                <TableHeader>Description</TableHeader>
                                <TableHeader>Ship Date</TableHeader>
                                <TableHeader>Transaction</TableHeader>
                        </TableRowStyle>
                        </TableHead>
                        <TableBody>
                            <TableRowStyle sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCellRow colSpan={3} align='center'>{ 'No order history found for the selected SKU.' }</TableCellRow>
                            </TableRowStyle>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
    
    // sx={{ height: [ '725px', '790px', '720px', '935px', '495px'] }}
    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                        <TableRowStyle>
                            <TableHeader>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                            </TableHeader>
                            <TableHeader>Order Number</TableHeader>
                            <TableHeader>Description</TableHeader>
                            <TableHeader>Ship Date</TableHeader>
                            <TableHeader>Transaction Number</TableHeader>
                        </TableRowStyle>
                    </TableHead>
                    <TableBody>
                        {
                            isProductsLoading ?
                                iterate5Times.map((item) => (
                                    <HistoryRowSkeleton key={'sh' + uuidv4} />
                                )) :
                                <React.Fragment>
                                    {
                                        historyData.map((row) => (
                                            <HistoryTableRow 
                                                row={row}
                                                openOverride={open}
                                            />
                                        ))
                                    }
                                </React.Fragment>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}