import { useSelector } from 'react-redux';
import {
    Box,
    Grid,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Skeleton
} from '@mui/material';
import { H3 } from '../HelperComponents/Typography';
import styled from '@emotion/styled';
import { IsObjectEmpty } from '../../utils/helpers';

const HeaderSkeleton = styled(Skeleton)`
    height: 39px;
    margin: 16px
`;

const BodySkeleton = styled(Skeleton)`
    height: 110px;
    margin: 16px;
`;

const HeaderItem = styled(Paper)`
    background-color: #1565c0;
    color: #fff;
    margin: 1em;
    border-radius: 0;
    h3{
        padding: .5em;
    }
`;

const TableCellSubHeader = styled(TableCell)`
    color: #1565c0;
    width: 25%;
    border-bottom: none;
    padding:  0 .5em;
`;

const TableCellPriceSubHeader = styled(TableCell)`
    color: #1565c0;
    width: 80%;
    border-bottom: none;
    padding:  0 .5em;
`;

const TableCellText = styled(TableCell)`
     border-bottom: none;
     padding:  0 .5em;
`;

const TableHeader = styled(TableCell)`
    background-color: #D5E7F7;
    padding: 0 .5em;
`;

function LineRowOutput(line) {
    return (
        <TableRow key={line.line.skuNum}>
            <TableCell>{line.line.lineNum}</TableCell>
            <TableCell>{line.line.skuNum}</TableCell>
            <TableCell>{line.line.vendor}</TableCell>
            <TableCell>{line.line.description}</TableCell>
            <TableCell>{line.line.itProtection}</TableCell>
            <TableCell>{line.line.extProtection}</TableCell>
            <TableCell>{line.line.status}</TableCell>
            <TableCell>{line.line.date}</TableCell>
            <TableCell>{line.line.wh}</TableCell>
            <TableCell>{line.line.qty === null ? 0 : line.line.qty}</TableCell>
            <TableCell>{line.line.unitPrice}</TableCell>
            <TableCell>{line.line.extPrice}</TableCell>
        </TableRow>
    )
}

function PaymentRowOutput(payment) {
    return (
        <TableRow key={`${payment.payment.paymentType}_${payment.payment.authNum}`}>
            <TableCell>{payment.payment.paymentType}</TableCell>
            <TableCell>{`$${payment.payment.amt}`}</TableCell>
            <TableCell>{payment.payment.date}</TableCell>
            <TableCell>{payment.payment.authNum}</TableCell>
        </TableRow>
    )
}

export default function OrderDetailsTab() {
    const { orderDetails, isLoading } = useSelector((state) => state.order);

    if (IsObjectEmpty(orderDetails) || isLoading) {
        return (
            <Box sx={{ flexGrow: 1, overflowX: 'hidden' }}>
                <Grid container >
                    <Grid xs={12}>
                        <HeaderSkeleton variant='rectangular'  />
                    </Grid>
                    <Grid xs={12}>
                        <BodySkeleton variant='rectangular' />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={12}>
                        <HeaderSkeleton variant='rectangular' />
                    </Grid>
                    <Grid xs={12}>
                        <BodySkeleton variant='rectangular' />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={12}>
                        <HeaderSkeleton variant='rectangular' />
                    </Grid>
                    <Grid xs={12}>
                        <BodySkeleton variant='rectangular' />
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <Box sx={{ flexGrow: 1, overflowX: 'hidden' }}>
            <Grid container >
                <Grid xs={12}>
                    <HeaderItem>
                        <H3>Order Information</H3>
                    </HeaderItem>
                </Grid>
                <Grid xs={6}>
                    <Table sx={{
                        margin: 'auto 1em',
                        border: 'none'
                    }} size="small" aria-label="order basic information">
                        <TableBody>
                            <TableRow>
                                <TableCellSubHeader>Order Number</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.orderNumber}</TableCellText>
                            </TableRow>
                            <TableRow>
                                <TableCellSubHeader>Home Number</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.homeNumber}</TableCellText>
                            </TableRow>
                            <TableRow>
                                <TableCellSubHeader>Alternative Number</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.altNumber}</TableCellText>
                            </TableRow>
                            <TableRow>
                                <TableCellSubHeader>Name</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.name}</TableCellText>
                            </TableRow>
                            <TableRow>
                                <TableCellSubHeader>Address</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.address}</TableCellText>
                            </TableRow>
                            <TableRow>
                                <TableCellSubHeader>Special Instructions</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.specialInstructions}</TableCellText>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid xs={6}>
                    <Table sx={{
                        margin: 'auto 1em',
                        border: 'none'
                    }} size="small" aria-label="order delivery information">
                        <TableBody>
                            <TableRow>
                                <TableCellSubHeader>Delivery Type</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.deliveryType}</TableCellText>
                            </TableRow>
                            <TableRow>
                                <TableCellSubHeader>Delivery Charge</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.deliveryCharge}</TableCellText>
                            </TableRow>
                            <TableRow>
                                <TableCellSubHeader>Date</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.date}</TableCellText>
                            </TableRow>
                            <TableRow>
                                <TableCellSubHeader>Sales Associate</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.salesAssoc}</TableCellText>
                            </TableRow>
                            <TableRow>
                                <TableCellSubHeader>Tax Code</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.taxCode}</TableCellText>
                            </TableRow>
                            <TableRow>
                                <TableCellSubHeader>Store</TableCellSubHeader>
                                <TableCellText>{orderDetails.orderInfo.store}</TableCellText>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs={12}>
                    <HeaderItem>
                        <H3>Line Items</H3>
                    </HeaderItem>
                </Grid>
                <Grid xs={12}>
                    <Box sx={{
                        overflowY: 'scroll',
                        maxHeight: ['15em', '13em', '14em', '14em', '8.5em'],
                        margin: 'auto 1em auto auto'
                    }}>
                        <Table
                            sx={{
                                margin: 'auto 1em',
                                border: 'none',
                                width: '98%'
                            }}
                            size="small"
                            stickyHeader
                            aria-label="order line items"
                        >
                        <TableHead>
                            <TableRow>
                                <TableHeader>Line</TableHeader>
                                <TableHeader>SKU #</TableHeader>
                                <TableHeader>Vendor</TableHeader>
                                <TableHeader>Description</TableHeader>
                                <TableHeader>Guard IT/Protection</TableHeader>
                                <TableHeader>Ext. Guard IT/Protection</TableHeader>
                                <TableHeader>Status</TableHeader>
                                <TableHeader>Date After</TableHeader>
                                <TableHeader>WH</TableHeader>
                                <TableHeader>Qty</TableHeader>
                                <TableHeader>Unit Price</TableHeader>
                                <TableHeader>Ext. Price</TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                orderDetails.lineItems.map((li) => (<LineRowOutput line={li} /> ))
                            }
                        </TableBody>
                    </Table>
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs={12}>
                    <HeaderItem>
                        <H3>Payment Information</H3>
                    </HeaderItem>
                </Grid>
                <Grid xs={8}>
                    <Box sx={{
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        minHeight: '8.5em',
                        margin: 'auto 1em .5em auto'
                    }}>
                        <Table sx={{ margin: 'auto 1em', border: 'none', width: '98%' }} size="small" aria-label="order payment information">
                            <TableHead>
                                <TableRow sx={{backgroundColor: '#D5E7F7'}}>
                                    <TableHeader>Payment Type</TableHeader>
                                    <TableHeader>Amount</TableHeader>
                                    <TableHeader>Date</TableHeader>
                                    <TableHeader>Auth No.</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    orderDetails.payments.map((pay) => (<PaymentRowOutput payment={pay} />))
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
                <Grid xs={4}>
                    <Box display="flex" justifyContent="flex-end">
                        <Table sx={{ margin: 'auto 1em .5em 1em', border:'none'}} size="small" aria-label="order basic information">
                            <TableBody>
                                <TableRow>
                                    <TableCellPriceSubHeader sx={{fontWeight:'bold'}}>Sales Total</TableCellPriceSubHeader>
                                    <TableCellText>{orderDetails.paymentInfo.salesTotal}</TableCellText>
                                </TableRow>
                                <TableRow>
                                    <TableCellPriceSubHeader sx={{fontWeight:'bold'}}>Delivery Charge</TableCellPriceSubHeader>
                                    <TableCellText>{orderDetails.paymentInfo.deliveryCharge}</TableCellText>
                                </TableRow>
                                <TableRow>
                                    <TableCellPriceSubHeader sx={{fontWeight:'bold'}}>Sub Total</TableCellPriceSubHeader>
                                    <TableCellText>{orderDetails.paymentInfo.subTotal}</TableCellText>
                                </TableRow>
                                <TableRow>
                                    <TableCellPriceSubHeader sx={{fontWeight:'bold'}}>Total</TableCellPriceSubHeader>
                                    <TableCellText>{orderDetails.paymentInfo.orderTotal}</TableCellText>
                                </TableRow>
                                <TableRow>
                                    <TableCellPriceSubHeader sx={{fontWeight:'bold'}}>Deposit</TableCellPriceSubHeader>
                                    <TableCellText>{orderDetails.paymentInfo.deposit}</TableCellText>
                                </TableRow>
                                <TableRow>
                                    <TableCellPriceSubHeader sx={{fontWeight:'bold'}}>Collected</TableCellPriceSubHeader>
                                    <TableCellText>{orderDetails.paymentInfo.collected}</TableCellText>
                                </TableRow>
                                <TableRow>
                                    <TableCellPriceSubHeader sx={{fontWeight:'bold'}}>Balance Due</TableCellPriceSubHeader>
                                    <TableCellText>{orderDetails.paymentInfo.balance}</TableCellText>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
