import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 744,
        sm: 834,
        md: 960,
        lg: 1024,
        xl: 1025,
        xxl: 1100,
        xxxl: 1300,
        desk: 1440,
        ldesk: 1441,
      },
    },
    MuiTypography: {
        defaultProps: {
            fontSize: '16px',
            fontFamily: "'Roboto', sans-serif",
        },
    },
    typography: {
      h1: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
      },
      h3: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: '1rem',
        fontWeight: 'bold',
      },
      h5: {
        fontSize: '.875rem',
        fontWeight: 'bold'
      },
      h6: {
        fontSize: '.75rem',
        fontWeight: 'bold'
      }
  },
  });

  export default theme;