import { PublicClientApplication } from "@azure/msal-browser";
import { authConfig, graphConfig } from "../features/Authentification/authProvider";

async function acquireAccessToken(){
    // This should be the same instance you pass to MsalProvider
    const msalInstance = new PublicClientApplication(authConfig);
    
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
        console.error("Could not get account, or account has not signed in. Please try again.");
    }
    const request = {
        scopes: ["User.Read"],
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken
};

/** * Attaches a given access token to a MS Graph API call. 
 * Returns information about the user * @param accessToken */

async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    try {
        const response = await fetch(
            `${graphConfig.graphMeEndpoint}?$select=onPremisesSamAccountName,userPrincipalName,displayName,onPremisesExtensionAttributes`,
            options
        );
        
        return await response.json();
    } catch (error) {
        return console.log(error);
    }
}

export {
    acquireAccessToken,
    callMsGraph
}
