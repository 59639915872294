import React, {
    useState,
    useMemo,
} from 'react';
import {  v4 as uuidv4 } from 'uuid';

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
    IconButton,
} from '@mui/material';
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
} from '@mui/icons-material';

import styled from '@emotion/styled'

const TableRowStyle = styled(TableRow)`
    .last-tr{
       border-right: none;
    }
`;

const TableCellRow = styled(TableCell)`
    border-right: 1px solid #A3A3A3;
    padding: 0 1em;
`;

const TableHeader = styled(TableCell)`
    background-color: #D5E7F7;
    border-right: 1px solid #A3A3A3;
    padding: 0 1em;
`;

const TableCellRowChild = styled(TableCell)`
    border-right: 1px solid #A3A3A3;
    padding: 0 1em;
    font-size: 1.15em;
`;

export default function HistoryTableRow({ row, openOverride }) {
    const [open, setOpen] = useState(false);

     useMemo(() => {
        setOpen(openOverride);
    }, [openOverride])

    // sx={{ height: [ '725px', '790px', '720px', '935px', '495px'] }}
    return (
       <React.Fragment>
            {
                <React.Fragment>
                    <TableRowStyle
                        sx={{
                            '& > *': { borderBottom: 'unset' },
                        }}
                    >
                        <TableCellRow>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        </TableCellRow>
                        <TableCellRow component="th" scope="row">
                            {row.OrderNumber}
                        </TableCellRow>
                        <TableCellRow>
                            {row.ProductDescription}
                        </TableCellRow>
                        <TableCellRow>
                            {new Date(row.ShipDate).toLocaleDateString('en-us')}
                        </TableCellRow>
                        <TableCellRow>
                            {row.TransactionNumber}
                        </TableCellRow>
                    </TableRowStyle>
                    <TableRow>
                        <TableCell style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            backgroundColor: "#e9e9e9"
                        }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="associated SKUs">
                                            <TableHead>
                                                <TableRowStyle>
                                                    <TableHeader>History Description</TableHeader>
                                                </TableRowStyle>
                                            </TableHead>
                                            <TableBody>
                                                {row.Notes.map((note) => (
                                                    <TableRow key={uuidv4}>
                                                        <TableCellRowChild>{note.FullDescription}</TableCellRowChild>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>
            }
        </React.Fragment>
    )
}