
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";

import TopNavigation from './components/TopNavBar/TopNavBarContainer';
import MainPage from './features/Returns/MainPage';
import Footer from './components/FooterBar/FooterContainer';

import { useSelector } from 'react-redux';


export default function App() {
    
    const { accounts, inProgress, instance } = useMsal();

    //to do: Call DB to get list from Redux
    const filteredProductList = useSelector((state) => state.product.filteredProductList);
    const skuList = useSelector((state) => state.product.skuList);

    const logOutHandler = () => {
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
            onRedirectNavigate: () => !BrowserUtils.isInIframe()
        })
    }

    return (
        <>
            <TopNavigation 
                logout={logOutHandler}
                accountName={accounts[0] ? accounts[0].name : ''}
            />
            <MainPage
                productList={filteredProductList}
                addSKUList={skuList}
                isLoading={inProgress !== 'none'}
            />
            <Footer />
        </>
    ); 
}