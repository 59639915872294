import {
    LoginType
} from 'react-aad-msal';

import { LogLevel } from "@azure/msal-browser";

const authConfig = {
    auth: {
        authority: process.env.REACT_APP_AZURE_AUTHORITY,
        clientId: process.env.REACT_APP_AZURE_CLIENTID,
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStatieInCookie: false
    },
    system: {
        navigateToLoginRequestUrl: false,
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
}
const authenticationParameters = {
    scopes: ["User.Read"]
}
const options = {
    loginType: LoginType.Redirect
}

const loginRequest = {
    scopes: ['openid', 'profile'],
};

const graphConfig = {
    graphMeEndpoint: process.env.REACT_APP_MSGRAPH_ENDPOINT,
}

export {
    authConfig,
    authenticationParameters,
    options,
    loginRequest,
    graphConfig,
}