import { useState } from 'react';

import {
    Box,
    Toolbar,
    Button,
    Collapse,
    AppBar,
    Tooltip,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import {
    ExpandLess,
    ExpandMore,
} from '@mui/icons-material';

import logo from '../../assets/images/RoomsToGoLogo-2.png';

import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const TopNavBox = styled(AppBar)`
    position: fixed;
    z-index: 10001;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #00529f;
    background-image: linear-gradient(to right, rgba(0, 82, 159, 1), #97beed);
    color: #fff;
    width:100%;
    margin-bottom: 1em;
    height: 50px;
`;

const TopNavMenu = styled(Menu)`
    .MuiPaper-root{
        background: transparent;
        box-shadow: none;
        ul{
            width: 12.15em;
            background: transparent;
            display: flexl;
            justify-content: center;
            li{
                background-color: #00529f;
                background-image: linear-gradient(to right, rgba(0, 82, 159, .7), #97beed);
                color: #fff;
                justify-content: center;
                &:hover {
                    box-shadow: inset 0 0 0 3rem rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
`;

const TopNavMenuItem = styled(MenuItem)`
    margin: 0 auto;
    width: 100%;
`;

const LinkButton = styled(Button)`
    color: #fff;
    width: auto;
    height: 50px;
    border-radius: 0;
    &:hover{
        background-color: rgba(25, 118, 210, 0.3);
    }
`;

export default function TopNavigation({ logout, accountName }) {
    const [anchorElUser, setAnchorElUser] = useState(null);
    
    const handleCloseUserMenu = () => {
         setAnchorElUser(null);
    };
    const handleOpenUserMenu = (event) => {
        if (Boolean(anchorElUser)) {
            handleCloseUserMenu();
        }
        else {
            setAnchorElUser(event.currentTarget);
        }
    };

    return(
        <TopNavBox
            aria-label='Rooms To Go Route Search navigation'
        >
            <Toolbar
                sx={{
                    minHeight: 'auto !important',
                    width: '100%'
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flex: '1 1 0',
                    maxWidth: ['20%','18%','10%', '8%']
                }}>
                     <img
                        src={logo}
                        alt='Rooms To Go Logo'
                        style={{
                            objectFit: 'contain',
                            height: '100%',
                            width: '100%'
                        }}
                    /> 
                </Box>
                <Box sx={{
                    display: 'flex',
                    flex: '1 1 0',
                    justifyContent: 'end'
                }}>
                    <Tooltip 
                        title='Open Account Settings'
                    >
                        <LinkButton
                            variant='text'
                            size="large"
                            onClick={handleOpenUserMenu}
                        >
                            {accountName}
                            {anchorElUser ? <ExpandLess /> : <ExpandMore />}
                        </LinkButton>
                    </Tooltip>
                    <TopNavMenu
                        id='top-nav-menu'
                        sx={{
                            mt: '35px', 
                        }}
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <TopNavMenuItem  onClick={logout}>
                            {'Logout'}
                        </TopNavMenuItem>
                    </TopNavMenu>
                </Box>
                </Toolbar>
        </TopNavBox>
    )
}

TopNavigation.prototype = {
    user: PropTypes.shape({
        logout: PropTypes.func.isRequired,
        accountName: PropTypes.string,
    })
}