import PropTypes from 'prop-types';
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import {
    Close
} from '@mui/icons-material';

export default function ImageModal({
    isOpen,
    modalClose,
    skuNum,
    description,
    imgSrc
}) {

    return (
        <Dialog
            open={isOpen}
            onClose={modalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <DialogTitle id="responsive-dialog-title">
                {`SKU - ${skuNum} - ${description}`}
                <IconButton 
                    aria-label="close"
                    onClick={modalClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <img
                    src={imgSrc}
                    alt={description}
                    style={{ 
                        objectFit: 'contain',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        height: 'auto',
                        padding: '8px',
                    }}
                />
            </DialogContent>
        </Dialog>
    )
}

ImageModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    modalClose: PropTypes.func.isRequired,
    skuNum: PropTypes.string,
    description: PropTypes.string,
    imgSrc: PropTypes.string
}