import { nanoid } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProductServices from '../../services/products';

import { addSkus } from '../../data/testData';

export const getProductList = createAsyncThunk(
    "products/getProductList",
    async ({ date, route, divison, isExpected }) => {
        const res = await ProductServices.getAllProducts(date, route, divison, isExpected);
        return res;
    }
)

export const initialState = {
    productList: [],
    filteredProductList: [],
    skuList: addSkus,
    isProductsLoading: false,
    hasLoadedSuccessful: false,
    isFirstLoad: true,
    previousSearchValues: {
        date: '',
        route: '',
        divison: '',
        isExpected: true
    },
};

export const searchProductSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        resetProductList: (state) => {
            return {
                ...state,
                productList: []
            }
        },
        resetFilterList: (state) => {
            return {
                ...state,
                filteredProductList: []
            }
        },
        searchOrders: (state, action) => {
            
            const searchOrdersFn = () => {
                const savedProductList = [...state.productList];
                const filteredList = [];

                savedProductList.forEach(p => {
                    if (p.SalesOrderNumber.toLowerCase().includes(action.payload.toLowerCase())) {
                        filteredList.push(p);
                    }
                    else {
                        p.RouteData.forEach(rd => {
                            if (rd.Sku.toLowerCase().includes(action.payload.toLowerCase()))
                                filteredList.push({
                                    ...p,
                                    RouteData: p.RouteData.filter(r => r.Sku.toLowerCase().includes(action.payload.toLowerCase()))
                                });
                            if (rd.VendorDescription.toLowerCase().includes(action.payload.toLowerCase()))
                                filteredList.push({
                                    ...p,
                                    RouteData: p.RouteData.filter(r => r.VendorDescription.toLowerCase().includes(action.payload.toLowerCase()))
                                });
                            if (rd.ReferenceColorFinish.toLowerCase().includes(action.payload.toLowerCase()))
                                filteredList.push({
                                    ...p,
                                    RouteData: p.RouteData.filter(r => r.ReferenceColorFinish.toLowerCase().includes(action.payload.toLowerCase()))
                                });
                        })
                    }
                })

                return [...new Map(filteredList.map(f => [f.SalesOrderNumber, f])).values()];
            }

            state.filteredProductList = searchOrdersFn();
        },
        updateSkuReturned: (state, action) => {
            //Payload must be the passed SKU ID from the selected checkbox
            const product = state.productList.find((p) => p.id === action.payload.rowId);
            if (product) {
                const sku = product.skus.find((s) => s.id === action.payload.skuId);
                if (sku) {
                    const isReturned = sku.returned;
                    sku.returned = !isReturned;
                }
            }
        },
        addUnexpected: (state, action) => {
            //Payload must be an object {sku#, reasonOption}
            const newSku = {
                'id': nanoid(),
                'sku': action.payload.skuNum,
                'description': action.payload.description
            }
            state.skuList.push(newSku);
        },
        removeUnexpected: (state, action) => {
            //Payload must be the skuId
            const sku = state.skuList.findIndex((sku) => sku.id === action.payload);
            state.skuList.splice(sku, 1);
        },
        setPreviousSearch: (state, action) => {
            state.previousSearchValues = {
                date: action.payload.date,
                route: action.payload.route,
                divison: action.payload.divison,
                isExpected: action.payload.isExpected
            };
        },
    },
    extraReducers: {
        [getProductList.fulfilled]: (state, action) => {
            return {
                ...state,
                isProductsLoading: false,
                isFilterSearching: false,
                hasLoadedSuccessful: [...action.payload].length > 0 ? true : false,
                isFirstLoad: false,
                productList: [...action.payload],
                filteredProductList: [...action.payload],
            };
        },
        [getProductList.pending]: (state) => {
            return {
                ...state,
                hasLoadedSuccessful: false,
                isProductsLoading: true
            };
        },
        [getProductList.rejected]: (state, action) => {
            return {
                ...state,
                hasLoadedSuccessful: false,
                isProductsLoading: false,
                isFirstLoad: false,
            };
        }
    }
})

export const {
    searchOrders,
    updateSkuReturned,
    addUnexpected,
    removeUnexpected,
    resetProductList,
    resetFilterList,
    setPreviousSearch,
} = searchProductSlice.actions;

export default searchProductSlice.reducer;