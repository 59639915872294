import {
    Typography
} from '@mui/material';

function H1({children, css}){
    return <Typography variant="h1" css={css}>{children}</Typography>
}

function H2({children, css}){
    return <Typography variant="h2">{children}</Typography>
}

function H3({children, css}){
    return <Typography variant="h3">{children}</Typography>
}

function H4({children, css}){
    return <Typography variant="h4">{children}</Typography>
}

function H5({children, css}){
    return <Typography variant="h5">{children}</Typography>
}

function H6({children, css}){
    return <Typography variant="h6">{children}</Typography>
}

//Normal sized <p> element
function P1({children, props}){
    return <Typography variant="body1" {...props}>{children}</Typography>
}

//Smaller sized <p> element
function P2({children, props}){
    return <Typography variant="body2" {...props}>{children}</Typography>
}

export {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    P1,
    P2
}