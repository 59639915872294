import {
    Box,
    Stack,
} from '@mui/material';


import { P2 } from '../HelperComponents/Typography';

export default function Footer() {
    const year = new Date().getFullYear();
    return(
        <Box
            borderTop='1px solid #A3A3A3'
            position='fixed'
            bottom={0}
            right={0}
            minWidth='100%'
            mt={2}
            sx={{
                backgroundColor: '#fff'
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems='baseline'
                p={1}
            >
                <P2> {`Rooms To Go © ${year} - Returns App`}</P2>
            </Stack>
        </Box>
    )
}