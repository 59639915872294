import {
    useState, 
    useEffect,
    useCallback,
} from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    Box,
    Tab,
    Tabs,
    IconButton,
    Typography,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';

import { Close } from '@mui/icons-material';

import OrderDetailsTab from '../../components/OrderModal/OrderDetailsTab';
import OrderNotesTab from '../../components/OrderModal/OrderNotesTab';
import {
    getOrderDetailsByID,
    getOrderNotesByID,
    getRelatedOrders,
} from '../../services/orderModalAPICalls';
import {resetOrderDetails} from '../../store/slices/orderSlice'
import styled from '@emotion/styled';

function a11yProps(index) {
  return {
    id: `order-details-tab-${index}`,
    'aria-controls': `order-details-tabpanel-${index}`,
  };
}

const ModalHeaderText = styled(Typography)`
    fontWeight: bold;
`;

const ModalTitleContainer = styled(Box)`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

export default function OrderModal({ isOpen, handleClose }) {
    const dispatch = useDispatch();
    const { 
        postParams, 
        relatedOrders,
    } = useSelector((state) => state.order);

    const [value, setValue] = useState(0);
    const [orderNumber, setOrderNumber] = useState(postParams.orderNum);
    
    const getRelatedOrder = useCallback(() => {
        dispatch(resetOrderDetails());
        getOrderDetailsByID(orderNumber, postParams.region, dispatch);
        getOrderNotesByID(orderNumber, postParams.region, dispatch);
    }, [orderNumber]);

     useEffect(() => {
        getOrderDetailsByID(orderNumber, postParams.region, dispatch);
        getOrderNotesByID(orderNumber, postParams.region, dispatch);
        getRelatedOrders(postParams.region, orderNumber, dispatch);
     }, []);
    
    useEffect(() => {
        getRelatedOrder();
    }, [getRelatedOrder])

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOrderChange = (event) => {
        if (event.target.value !== 0) {
            setOrderNumber(event.target.value);
        }
    };

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth='95vw'
                sx={{
                    marginTop: '2em',
                    marginBottom: '0'
                }}
            >
                <DialogTitle>
                    <ModalTitleContainer>
                        <Box>
                            <ModalHeaderText variant="h3">
                                {`Order #${orderNumber} Details`}
                            </ModalHeaderText>
                        </Box>
                        <Box
                            display={'flex'}
                            marginRight={'3em'}
                            alignItems={'baseline'}
                        >
                            <ModalHeaderText variant="h3">
                                {`Related Orders`}
                            </ModalHeaderText>
                            {
                                relatedOrders.length > 0 ? 
                                <FormControl
                                    sx={{
                                        ml: 1,
                                        minWidth: 125
                                    }}
                                    size="small"
                                >
                                    <Select
                                        id="ordernum-select"
                                        value={orderNumber}
                                        onChange={handleOrderChange}
                                    >
                                        {
                                            relatedOrders.map(r => (
                                                <MenuItem value={r.KeyId}>{r.KeyId}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                :
                                <FormControl
                                    sx={{
                                        ml: 1,
                                        minWidth: 125
                                    }}
                                    size="small"
                                    disabled
                                >
                                    <Select
                                        id="ordernum-select"
                                        value={0}
                                        onChange={handleOrderChange}
                                    >
                                        <MenuItem value={0}>{'No Related Orders'}</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                        </Box>
                    </ModalTitleContainer>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Box sx={{ width: '100%' }}>
                    <Tabs value={value} onChange={handleTabChange} centered aria-label="order details tabs">
                        <Tab label="Sales Order Details" {...a11yProps(0)} />
                        <Tab label="Order Notes" {...a11yProps(1)} />
                    </Tabs>
                     <div
                        role="tabpanel"
                        hidden={value !== 0}
                        id={`order-details-tabpanel-0`}
                        aria-labelledby={`order-details-tab-0`}
                    >
                        <OrderDetailsTab />
                    </div>
                    <div
                        role="tabpanel"
                        hidden={value !== 1}
                        id={`order-details-tabpanel-1`}
                        aria-labelledby={`order-details-tab-1`}
                    >   
                        <OrderNotesTab />
                    </div>
                </Box>
            </Dialog>
        </>
    )
}