import { useState } from 'react';
import {
    Button,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
    OpenInNew,
} from '@mui/icons-material';
import { red, orange, green, blue } from '@mui/material/colors';

//Colors for Dispatch cell
const redNormal = red[900];
const redHover = red[600];

const yellowNormal = orange[900];
const yellowHover = orange[600];

const greenNormal = green[800];
const greenHover = green[600];

const primary = blue[800];
const primaryHover = blue[700]


function CellCardButton({ modalFn, children }) {
    return (
        <Button
            variant="text"
            onClick={modalFn}
        >
            <OpenInNew sx={{ marginRight: '8px' }} />
            {children}
        </Button>
    );
}

function CellCardLink({ code, href, children }) {
    const [hover, onHover] = useState(false);

    const codeColor = (code) => {
        switch (code) {
            case 1:
                return {
                    normal: greenNormal,
                    hover: greenHover
                };
            case 2:
                return {
                    normal: yellowNormal,
                    hover: yellowHover
                };
            case 3:
                return {
                    normal: redNormal,
                    hover: redHover
                };
            case 0:
            default:
                return {
                    normal: primary,
                    hover: primaryHover
                };
        }
    }

    return (
        <a
            target="_blank"
            rel='noreferrer'
            href={href}
            underline='none'
            style={{
                display: 'flex',
                alignItems: 'end',
                color: hover ? codeColor(code).hover : codeColor(code).normal
            }}
            onMouseEnter={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
        >
            <OpenInNew sx={{marginRight: '8px'}} />
            {children}
        </a>
    )
}

CellCardLink.propTypes = {
    code: PropTypes.number,
    href: PropTypes.string,
}

export {
    CellCardLink,
    CellCardButton,
};